<template>
    <div class="VideoBox" v-if="list.length>0">
        <div class="VBox">
            <video :src="list[actived].resourceUrl" ref="myVideo" @play="HandleFullScreenOne" @ended="HandleExitFullScreenOne" controls="controls" style="width:100%;height: 100%;border-radius: 1rem;"></video>
        </div>
        <div class="VInfo" v-if="show">
            <div class="Title">{{ list[actived].title }}</div>
            <div class="summary" style="text-indent: 2rem;">{{ list[actived].summary }}</div>
        </div>
        <div class="VList">
            <div class="VItem" v-for="(item,index) in list" :key="index" @click="changeVideo(index)">
                <video :src="item.resourceUrl" style="width:100%;height: 100%;border-radius: 0.8rem;"></video>
            </div>
        </div>
    </div>
    <div v-if="list.length==0" class="NoData">
        <img src="../../assets/Public/ZWSP.png">
        <div>暂无视频</div>
    </div>
</template>
<script>
export default {
    props: {
        list: {
            type: Array,
            default: []
        },
        show: {
            type: Boolean,
            default: true
        },
    },
    data () {
        return {
            actived: 0
        }
    },
    mounted () {
        document.addEventListener('fullscreenchange', this.handleFullscreenChange);
        document.addEventListener('mozfullscreenchange', this.handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', this.handleFullscreenChange);
    },
    methods: {
        changeVideo (idx) {
            this.actived = idx
        },
        // 退出全屏暂停播放
        handleFullscreenChange () {
            const video = this.$refs.myVideo;
            if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
                video.pause(); // 当视频退出全屏时暂停播放
            }
        },
        //点击视频全屏播放
        HandleFullScreenOne (e) {
            this.launchFullscreen(e.target);
        },
        // 进入全屏
        launchFullscreen (element) {
            //此方法不可以在異步任務中執行，否則火狐無法全屏
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
            } else if (element.oRequestFullscreen) {
                element.oRequestFullscreen();
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullScreen();
            } else {
                var docHtml = document.documentElement;
                var docBody = document.body;
                var videobox = element;
                var cssText = "width:100%;height:100%;overflow:hidden;";
                docHtml.style.cssText = cssText;
                docBody.style.cssText = cssText;
                videobox.style.cssText = cssText + ";" + "margin:0px;padding:0px;";
                document.IsFullScreen = true;
            }
        },
        HandleExitFullScreenOne (e) {
            this.exitFullscreen(e.target);
        },
        exitFullscreen (element) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.oRequestFullscreen) {
                document.oCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else {
                var docHtml = document.documentElement;
                var docBody = document.body;
                var videobox = element;
                docHtml.style.cssText = "";
                docBody.style.cssText = "";
                videobox.style.cssText = "";
                document.IsFullScreen = false;
            }
        },
    },
    beforeUnmount () {
        // 销毁监听视频
        document.removeEventListener('fullscreenchange', this.handleFullscreenChange);
        document.removeEventListener('mozfullscreenchange', this.handleFullscreenChange);
        document.removeEventListener('webkitfullscreenchange', this.handleFullscreenChange);
    }
}
</script>
<style lang="less" scoped>
@import '../../assets/unify.less';

.VideoBox {
    width: 100%;
    height: 100%;
    .VBox {
        height: 58vh;
        width: 100%;
        background: #000;
        border-radius: 1rem;
        box-shadow: 0px 0px 2rem 0px rgba(0, 0, 0, 0.5);
    }
    .VInfo {
        width: 80%;
        font-size: @Content_Text_Size;
        line-height: @Content_Text_LineHeight;
        padding: 2vh 0 0;
        .Title {
            margin: 1vh 0;
            text-align: left;
            font-size: @Second_Title_Size;
        }
    }
    .VList {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        padding: 2vh 0 0;
        .VItem {
            flex-shrink: 0;
            width: 20%;
            margin-right: 2%;
            cursor: pointer;
            border-radius: 1rem;
            background: #000;
        }
    }
}
.NoData {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: @Content_Text_Size;
    color: #999;
    img {
        width: 30%;
        margin-bottom: 3vh;
    }
}
</style>