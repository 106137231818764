<template>
    <div class="InfoBox" v-if="Introduction">
        <div class="Head" style="width: 16rem; height: 12rem;margin-right: 1rem;">
            <img :src="Introduction.logoUrl" style="width: 100%; height: 80%">
            <div style="height: 20%;text-align: center;">{{ Introduction.title }}</div>
        </div>
        <div class="Summary">{{ Introduction.summary }}</div>
    </div>
</template>
<script>
export default {
    props: ['Introduction']
}
</script>
<style lang="less" scoped>
@import '../../../assets/unify.less';

.InfoBox {
    width: 100%;
    display: flex;

    .Head {
        flex-shrink: 0;
    }
    .Summary {
        flex-grow: 1;
        text-indent: 2rem;
        line-height: @Content_Text_LineHeight;
        font-size: @Content_Text_Size;
        overflow-y: auto;
    }
}
</style>